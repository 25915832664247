import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@qga/roo-ui/components';
import ImageFallback from 'components/Image/ImageFallback';
import ImageLoading from 'components/Image/ImageLoadingIndicator';
import ImageInner from 'components/Image/ImageInner';
import { useImageLoader } from './useImageLoader';

const ImageLoader = ({ src, srcSet, onLoad, width, height, cover, ...rest }) => {
  const { isLoading, displayedLoading, error, loadedImg } = useImageLoader({ src, srcSet, onLoad });
  const isImageReady = !!loadedImg.src || !!loadedImg.srcSet;
  const hasSuppliedDimensions = !!width || !!height;

  // This keeps a placeholder width/height should an image take less than 500ms to show.
  const showPlaceholder = !error && !isLoading && !isImageReady;

  if (showPlaceholder) return <Box width={width} height={height} data-testid="placeholder" />;
  if (isLoading) return <ImageLoading {...rest} width={width} height={height} />;
  if (error) return <ImageFallback {...rest} width={width} height={height} />;
  if (isImageReady)
    return (
      <ImageInner
        {...rest}
        src={loadedImg.src}
        srcSet={loadedImg.srcSet}
        width={hasSuppliedDimensions ? width || '100%' : loadedImg.naturalWidth}
        height={hasSuppliedDimensions ? height || '100%' : loadedImg.naturalHeight}
        animate={displayedLoading}
        cover={cover}
      />
    );

  return null;
};

ImageLoader.propTypes = {
  src: PropTypes.string,
  srcSet: PropTypes.string,
  onLoad: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array]),
  cover: PropTypes.bool,
};

ImageLoader.defaultProps = {
  src: null,
  onLoad: null,
  srcSet: null,
  width: null,
  height: null,
  cover: true,
};

export default ImageLoader;
