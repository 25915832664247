import React from 'react';
import ImageLoader from './ImageLoader';
import ImageLazyLoader from './ImageLazyLoader';

const Image = React.memo(
  ({ src = null, srcSet = null, onLoad = null, width = null, height = null, lazy = false, cover = true, alt, ...rest }: ImageProps) => {
    if (lazy)
      return <ImageLazyLoader src={src} srcSet={srcSet} onLoad={onLoad} cover={cover} width={width} height={height} alt={alt} {...rest} />;
    return <ImageLoader src={src} srcSet={srcSet} onLoad={onLoad} cover={cover} width={width} height={height} alt={alt} {...rest} />;
  },
);

Image.displayName = 'Image';

type ImageProps = {
  src: string;
  srcSet: string;
  alt: string;
  onLoad?: () => void;
  width: string | number | string[] | number[];
  height: string | number | string[] | number[];
  lazy?: boolean;
  cover?: boolean;
};

export default Image;
